
import { defineComponent, ref, getCurrentInstance, onMounted, nextTick, onActivated, onDeactivated, computed } from 'vue'
import PictureWaterfall from './components/water-fall/index.vue'
import PictureCard from './components/picture-card/index.vue'
import TYMLCard from './components/tyml-card/index.vue'
import VideoCard from './components/video-card/index.vue'
import Load from './components/load/index.vue'
import { rafThrottle } from './components/utils'
import { checkSnack, copyToClipboard, displayToastMessage, repalacePath } from '@/common/utils'
import cookies from 'js-cookie';
import { getMetaInfo, header, extractTitleFromMetaInfo, getDefaultTitle, getLdJson } from '@/common/seo'
import { setCommonParamsCookiesInServer } from '@/common/common-params'
import { immediateSendLog, getKwaiLog } from '@/common/radar';
import { getQuery, commonPvParams } from '@/common/utils'
import { Picture, TopicApi, TopicItem } from '~/api/topic'
import { Feed } from '~/api/feed'
import { RESULT_OK, WebFeedType } from '~/api/base'

export default defineComponent<any>({
  components: {
    PictureWaterfall,
    PictureCard,
    Load,
    VideoCard,
    TYMLCard
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as { proxy: Record<string, any> }
    const isLoading = ref(false)
    const cursorIndex = ref('')
    const pictureList = ref<any>([])
    const topicContainerRef = ref()
    const showEmptyImg = ref(false)
    const topicNameContent = ref('')
    const showScrollToTopIcon = ref(false)
    const fmp_time = Date.now()
    const isVideoShow = ref(false)
    const videoFeeds = ref<Feed[]>([])

    const topicDescription = computed(() => {
      const { isSnack } = checkSnack()
      return proxy.$root.$t('k_1207313')
        .replace('${0}', isSnack ? 'Snack' : 'Kwai')
        .replace('${1}', topicNameContent.value)
    })

    // 获取图片列表
    const getData = async (firstRequest = false) => {
      if (isLoading.value || cursorIndex.value === 'no_more') {
        return
      }

      isLoading.value = true
      try {
        const response = await TopicApi(proxy?.$axios).getTopicList({
          topic: encodeURIComponent(topicNameContent.value?.trim()?.replace(/\s/g, '-')),
          pcursor: cursorIndex.value,
          count: 25,
        })

        const { result, feeds = [], pcursor = '' } = response || {}

        if (result === RESULT_OK) {
          if (feeds?.length) {
            pictureList.value.push(...feeds)
            cursorIndex.value = pcursor

            // 判断内容是否撑满页面
            nextTick(() => {
              if (!topicContainerRef.value) return;
              const windowHeight = topicContainerRef.value.clientHeight || document?.body?.clientHeight;
              const scrollHeight = topicContainerRef.value.scrollHeight || document?.body?.scrollHeight;
              if (windowHeight + 100 >= scrollHeight) getData()
            })
          } else {
            cursorIndex.value = 'no_more'
          }
          showEmptyImg.value = firstRequest && feeds.length === 0
        }
        isLoading.value = false
      } catch (error) {
        if (pictureList.value.length === 0) {
          showEmptyImg.value = true
        }
      } finally {
        isLoading.value = false
      }
    }

    const sendPv = () => {
      const params = commonPvParams({ defaultPageSource: 'topic' })
      // 获取启动config
      const country = proxy.$store.state.startup.countryInfo
      const user_id = cookies.get('user_id')
      immediateSendLog({
          type: 'pv',
          name: 'SEO_PC_TOPIC_PAGE',
          value: {
              country,
              user_id,
              topic_name: topicNameContent.value,
              ...params
          }
      })
    }

    onMounted(() => {
      const webLogger = getKwaiLog();
      try {
        webLogger?.plugins?.radar?.fmp(fmp_time);
      } catch (error) {
        console.error(error);
      }

      if (proxy.$route.params) {
        proxy.$store.commit('setTopicName', proxy.$route.params?.content?.replace(/(-)/g, ' '));
        proxy.$store.commit('setSearchValue', proxy.$route.params?.content?.replace(/(-)/g, ' '));
      }
      topicNameContent.value = proxy.$store.state.topicName;
      sendPv()

      showEmptyImg.value = false
      if (proxy?.topicPictureList?.length) {
        pictureList.value = proxy.topicPictureList
        cursorIndex.value = proxy.cursor

        // 判断内容是否撑满页面
        if (pictureList.value.length) {
          nextTick(() => {
            if (!topicContainerRef.value) return;
            const windowHeight = topicContainerRef.value.clientHeight || document?.body?.clientHeight;
            const scrollHeight = topicContainerRef.value.scrollHeight || document?.body?.scrollHeight;
            if (windowHeight + 100 >= scrollHeight) getData()
          })
        }
      }

      if (proxy.isServerFetched && !proxy?.topicPictureList?.length) {
        showEmptyImg.value = true
      }

      if (!proxy.isServerFetched) {
        getData(true)
      }
    })

    onActivated(() => {
      topicContainerRef.value.scrollTo(0, scrollHeight.value)
    })

    onDeactivated(() => {
      scrollHeight.value = topicContainerRef.value.scrollTop
    })

    const handlePictureClick = (picutreItem: any) => {
      proxy.$store.commit('setClickedPicture', picutreItem);
      const query = getQuery()
      const jumpLink = `/@${picutreItem.kwai_id}/picture/${picutreItem.pic_id_str}`
      proxy.$router.push({ path: jumpLink, query: { ...query, page_source: 'topic' } })
    }

    const checkScrollPosition = rafThrottle(() => {
      const {scrollHeight, scrollTop, clientHeight} = topicContainerRef?.value
      const distanceFromBottom = scrollHeight - clientHeight - scrollTop;
      if (distanceFromBottom <= 300) {
        getData()
      }

      showScrollToTopIcon.value = scrollTop > 100
    })

    const handleScrollToTop = () => {
      topicContainerRef?.value?.scrollTo({behavior: "smooth", top: 0})
    }

    const scrollHeight = ref(0)

    const selectedSlide = ref<string>('Topic')

    const handleMenuClick = async(name: string) => {
      if(!name) {
        proxy.$router.push({ path: `/foryou`, query: { ...getQuery(), page_source: 'topic'} });
        return
      }
      proxy.$router.push({ path: `/discover`, query: { ...getQuery(), page_source: 'topic', tabName: name } })
    }

    const handleTopicShare = () => {
      copyToClipboard(window.location.href)
      displayToastMessage(proxy, proxy.$root.$t('k_446636'))
    }

    const handleVideoCardClick = (item?: Feed) => {
      if (!item) {
        return
      }
      
      isVideoShow.value = true
      videoFeeds.value = [item]
      const path = `/@${item?.kwai_id}/video/${item?.photo_id_str}`
      repalacePath(path)
    }

    const handleReturn = () => {
      isVideoShow.value = false
      repalacePath(`/topic/${topicNameContent.value}`)
    }

    return {
      pictureList,
      getData,
      handlePictureClick,
      selectedSlide,
      handleMenuClick,
      checkScrollPosition,
      topicContainerRef,
      isLoading,
      showEmptyImg,
      cursorIndex,
      handleTopicShare,
      topicNameContent,
      topicDescription,
      showScrollToTopIcon,
      handleScrollToTop,
      handleVideoCardClick,
      handleReturn,
      videoFeeds,
      isVideoShow,
      WebFeedType
    }
  },
  async asyncData(ctx: any) {
    let seoData = []
    let isServerFetched = false
    let metaInfoData = []
    let topicPictureList: Array<TopicItem> = []
    let cursor: number | string = 0
    let title = getDefaultTitle(ctx)

    if(!ctx.route.query?.tabName && !ctx.route.params?.content) return ctx.redirect('/foryou');

    if (process.server) {
      // seo数据
      try {
        setCommonParamsCookiesInServer({
          bucket: ctx.store.state.startup.bucket,
          host: ctx.req.headers.host,
          ctx,
          countryInfo: ctx.store.state.startup.countryInfo,
        })

        isServerFetched = true

        const content = ctx?.route?.params?.content || ''
        ctx.store.commit('setTopicName', content?.replace(/(-)/g, ' '));
        ctx.store.commit('setSearchValue', content?.replace(/(-)/g, ' '));
        let pidStrList: Array<string> = []

        // 先请求Topic内容接口，拿到信息后再把数据带给后端，生成metaInfo
        const topicName = ctx?.route?.params?.content || ''
        const pictureListRes = await TopicApi(ctx?.$axios).getTopicList({
          topic: encodeURIComponent(topicName?.trim()?.replace(/\s/g, '-')),
          pcursor: 0,
          count: 25,
        })
        if (pictureListRes?.result === RESULT_OK) {
          topicPictureList = pictureListRes?.feeds || []
          cursor = pictureListRes?.pcursor || '0'
          pidStrList = topicPictureList?.map((item) => {
            if (item?.web_feed_type === WebFeedType.PHOTO_CARD) {
              return (item as Feed)?.photo_id_str || ''
            } else if (item?.web_feed_type === WebFeedType.PICTURE_CARD) {
              return (item as Picture)?.pic_id_str || ''
            } else {
              return ''
            }
          }
          )?.filter(pid => pid !== '') || [];
        }

        const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || ''

        // 获取google ldjson 标签
        let seoPromise = getLdJson({ ctx, params: { url, pidStrList: pidStrList || '' } })
        // 获取meta信息
        let metaInfoPromise = getMetaInfo({ ctx, params: { url, locale: ctx.req.headers['accept-language'], pidStrList: pidStrList || '' } })

        let [seoRes, metaInfoRes] = await Promise.all([seoPromise, metaInfoPromise])

        if (seoRes.status === 200) {
          seoData = seoRes.data
        }

        if (metaInfoRes.status === 200) {
          metaInfoData = metaInfoRes.data
          title = extractTitleFromMetaInfo({ ctx, metaInfoData})
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      topicPictureList,
      seoData,
      isServerFetched,
      metaInfoData,
      title,
      cursor,
    }
  },
  head() {
    const head = header(this) as any

    return {
      ...head,
    }
  },
})
