import Vuex from 'vuex';
import { checkSnack } from '~/common/utils';

const createStore = () => {
    const store = new Vuex.Store({
        state: {
            startup: {},
            videoList: [],
            clickedPicture: {},
            isSnack: false,
            searchValue: '',
            leftTablist: [],
            globalMuted: true,
            topicName: '',
            isLogin: false,
            loginChannel: [],
            userInfo: {},
        },
        mutations: {
            setStartup(state, data) {
                state.startup = data;
            },
            setVideoList(state, data) {
                state.videoList = data;
            },
            setClickedPicture(state, data) {
                state.clickedPicture = data;
            },
            setIsSnack(state, data) {
                state.isSnack = data;
            },
            setSearchValue(state, data) {
                state.searchValue = data;
            },
            setLeftTab(state, data) {
                state.leftTablist = data;
            },
            setGlobalMuted(state, data) {
                state.globalMuted = data;
            },
            setTopicName(state, data) {
                state.topicName = data;
            },
            setIsLogin(state, data) {
                state.isLogin = data;
            },
            setLoginChannel(state, data) {
                state.loginChannel = data;
            },
            setUserInfo(state, data) {
                state.userInfo = data;
            },
        },
        actions: {
            // 当csr时 无法调用这个
            async nuxtServerInit({ commit }, ctx) {
                const { $axios } = ctx;
                const res = await $axios.$$post('overseaAPI::/rest/o/w/pc/startup', {
                    shareDeviceId: ctx.route?.query?.share_device_id,
                });

                // ban 国内 ip
                if (res.result === 5002) {
                    ctx.redirect('/error');
                }

                if (res.result === 1) {
                    commit('setStartup', res.data || {});
                }
                const { isSnack } = checkSnack(ctx);
                commit('setIsSnack', isSnack);

                //  侧边栏获取
                const leftTablist: { key: string; element: string }[] = [];
                try {
                    const leftTabListRes = JSON.parse(await ctx.app.$kconf.getValue('overseaServer.share.pcLeftTabList'));
                    for (const key in leftTabListRes) {
                        if (Object.prototype.hasOwnProperty.call(leftTabListRes, key)) {
                            const element = leftTabListRes[key];
                            leftTablist.push({ key, element });
                        }
                    }
                    commit('setLeftTab', leftTablist);
                } catch (error) {
                    console.log(error);
                }
            },
            // 当csr调用
            async getStartup({ commit }, ctx) {
                const { $axios } = ctx;
                const res = await $axios.$$post('overseaAPI::/rest/o/w/pc/startup', {
                    shareDeviceId: ctx.route?.query?.share_device_id,
                });
                if (res.result === 1) {
                    commit('setStartup', res.data || {});
                }
            },
        },
    });
    return store;
};
export default createStore;
