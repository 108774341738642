
import { defineComponent, SetupContext, getCurrentInstance, onUnmounted, watch, onMounted, ref } from 'vue';
import Loading from '../components/Loading/index.vue';
import TopBar from '../components/TopBar/index.vue';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { FirebaseConfig } from '~/constants/base';
import cookies from 'js-cookie';
import { sendLog, radarLog } from '~/common/radar';
import { initDeviceFingerprint } from '~/common/utils';
import { ReportFirebaseTokenRequest, UserApiService } from '~/api/user';
import { getKpn } from '~/common/common-params';

const firebaseApp = initializeApp(FirebaseConfig);

/**
 * @zh 通知权限状态
 */
enum OpenStatus {
    UnSupported = 0, // 不支持通知：1、系统不支持 2、firebase 不支持(如防火墙限制等)
    Close = 1, // 未开启
    Opend = 2, // 已开启
    Opening = 3, // 本次开启
    Closing = 4, // 本次关闭
    MaybeLater = 5,
    EnableNow = 6,
}

export default defineComponent({
    components: {
        Loading,
        TopBar,
    },

    setup(props, ctx: SetupContext) {
        const { proxy } = getCurrentInstance() as any;
        const startupData: any = proxy.$store.state.startup;
        const UserApi = new UserApiService();
        const addToDesktopReminder = proxy.$store.state.startup?.addToDesktopReminder || 'false';

        const loading = ref(false);
        const pageType = ref('normal');
        const downloadModel = ref(false);
        const loginModel = ref(false);

        const setLoading = (isLoading: boolean) => {
            loading.value = isLoading;
        };

        const hideDownloadModel = () => {
            downloadModel.value = false;
        };

        const hideLoginModal = () => {
            loginModel.value = false;
        };

        const handleInstallWebApp = (e: Event) => {
            console.log('handleInstallWebApp', ' start');
            setTimeout(() => {
                window?.deferredPrompt?.prompt();
            }, 1000);
            window?.deferredPrompt?.userChoice.then((choiceResult: any) => {
                // @ts-ignore
                window.deferredPrompt = null;
                window.removeEventListener('click', handleInstallWebApp);
                localStorage.setItem('isShowPrompt', Date.now().toString());
                if (choiceResult.outcome === 'accepted') {
                    sendLog({
                        type: 'click',
                        name: 'ADD_TO_DESKTOP',
                        value: {
                            is_success: 1,
                        },
                    });
                    console.log('handleInstallWebApp', ' accepted');
                } else if (choiceResult.outcome === 'dismissed') {
                    sendLog({
                        type: 'click',
                        name: 'ADD_TO_DESKTOP',
                        value: {
                            is_success: 0,
                        },
                    });
                    console.log('handleInstallWebApp', ' dismissed');
                }
            });
            // window.removeEventListener('click', handleInstallWebApp)
        };

        const registerSW = async () => {
            try {
                const registration = await navigator.serviceWorker.register(`${window?.location?.origin}/firebase-messaging-sw.js`, {
                    scope: '/firebase-cloud-messaging-push-scope',
                });

                return !!registration;
            } catch {
                return false;
            }
        };

        const reportToken = async (token: string, extraParams = {}) => {
            console.log('token: ', token);
            const bucket = startupData.bucket || cookies.get('bucket');
            const kpn = getKpn({ bucket, host: window.location.host });

            const data: ReportFirebaseTokenRequest = {
                push_token: token,
                timestamp: Date.now(),
                domain: window.location.origin,
                url: window.location.href,
                ua: window.navigator.userAgent,
                /**
                 * @en `default_web_did_` as default prefix
                 * @en can be analyzed by the prefix
                 */
                web_did: cookies.get('webDid') || `default_web_did_${Date.now()}`,
                kwai_id: proxy?.$store?.state?.userModule?.userInfo?.user?.kwai_id,
                user_id_str: cookies.get('user_id'),
                session_id: cookies.get('sessionId'),
                countryInfo: startupData.countryInfo || cookies.get('countryInfo'),
                bucket,
                kpn,
                extra_params: { platform: 'pc', ...extraParams },
            };

            if (!token) {
                radarLog({
                    name: 'Get_Firebase_Token_Error',
                    category: 'KWAI_SHARE',
                    event_type: 'click',
                    extra_info: JSON.stringify(data),
                });
                return;
            }

            await UserApi.reportFirebaseToken(data);
        };

        const registerFirebaseMessaging = async () => {
            try {
                const isSupport = await isSupported();
                console.log('isSupport: ', isSupport);
                if (!window?.navigator?.serviceWorker || !isSupport) {
                    return;
                }

                /**
                 * @zh 在注册 firebase 前先判断是否已经开启
                 */
                const hasOpened = window.Notification?.permission === 'granted';

                const isRegisterSW = await registerSW();
                if (!isRegisterSW) {
                    return;
                }
                const message = getMessaging(firebaseApp);
                const token = await getToken(message);
                await reportToken(token);

                if (!hasOpened) {
                    /**
                     * @zh 之前 “未开启” 才上报 “本次开启”
                     */
                    sendNotificationPermissionLog(OpenStatus.Opening);
                }
            } catch (e) {
                if (window.Notification?.permission === 'default') {
                    // 用户手动拒绝
                    sendNotificationPermissionLog(OpenStatus.Closing);
                }
            }
        };

        const getCurrentPermissionStatus = async (): Promise<OpenStatus> => {
            const isSupport = await isSupported();

            if (!isSupport) {
                radarLog({
                    name: 'Unsupport_Firebase',
                    category: 'KWAI_SHARE',
                    event_type: 'click',
                });
            }

            if (typeof window?.Notification?.permission === 'undefined' || !isSupport) {
                return OpenStatus.UnSupported;
            }

            return window?.Notification?.permission === 'granted' ? OpenStatus.Opend : OpenStatus.Close;
        };

        const sendNotificationPermissionLog = (status: OpenStatus) => {
            sendLog({
                type: 'click',
                name: 'PWA_AUTHORITY',
                value: {
                    status,
                },
            });
        };

        watch(
            () => proxy.$route.path,
            (newVal: string) => {
                if (newVal.indexOf('/video/') !== -1) {
                    pageType.value = 'video';
                } else if (newVal.indexOf('/picture') !== -1) {
                    pageType.value = 'picture';
                } else if (newVal.indexOf('/embed') !== -1) {
                    pageType.value = 'embed';
                } else if (newVal.indexOf('/playlist') !== -1) {
                    pageType.value = 'playlist';
                } else {
                    pageType.value = 'normal';
                }
            },
            { immediate: true }
        );

        onMounted(async () => {
            try {
                initDeviceFingerprint();
                proxy.$bus.on('isLoad', setLoading);
                proxy.$bus.on('show-model', (val: boolean) => {
                    if (proxy.$store.state.isLogin || proxy.$store.state.loginChannel.length == 0) {
                        downloadModel.value = val;
                    } else {
                        loginModel.value = val;
                    }
                });
                proxy.$bus.on('show-download', (val: boolean) => {
                    downloadModel.value = val;
                });

                /**
                 * web push
                 */

                registerFirebaseMessaging();

                const status = await getCurrentPermissionStatus();
                sendNotificationPermissionLog(status);


                if (!localStorage.getItem('isShowPrompt') && addToDesktopReminder === 'true') window.addEventListener('click', handleInstallWebApp);
            } catch (e) {
                console.error('default.vue onMounted error: ', e);
            }
        });

        return {
            loading,
            pageType,
            downloadModel,
            loginModel,
            hideDownloadModel,
            hideLoginModal,
            handleInstallWebApp,
        };
    },
    head() {
        const host = (this as any).$host || location?.host;
        const languagemap = (this as any).$i18n.localeCodes.map((el: string) => {
            return {
                rel: 'alternate',
                hreflang: el,
                href: `https://${host + decodeURI(this.$route.path)}?lang=${el}`,
            };
        });

        return {
            link: [
                {
                    rel: 'canonical',
                    href: `https://${host + decodeURI(this.$route.path)}`,
                },
                ...languagemap,
            ],
        };
    },
});
