
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import cookies from "js-cookie";
import { getMetaInfo, header, extractTitleFromMetaInfo, getDefaultTitle, getLdJson } from "@/common/seo";
import { setCommonParamsCookiesInServer } from "@/common/common-params";
import { immediateSendLog, getKwaiLog } from "@/common/radar";
import { commonPvParams } from "@/common/utils";
import PictureDetail from "@/components/PictureDetail/index.vue";
import { PictureDetailInfo } from "~/common/type";

export default defineComponent({
  components: {
    PictureDetail,
  },
  setup(props, ctx) {
    const { proxy } = getCurrentInstance() as any;
    const pictureDetailInfo = ref<PictureDetailInfo>();
    const fmp_time = Date.now();

    onMounted(() => {
      const webLogger = getKwaiLog();
      try {
        webLogger?.plugins?.radar?.fmp(fmp_time);
      } catch (error) {
        console.error(error);
      }

      if (proxy?.pictureDetail) {
        pictureDetailInfo.value = proxy.pictureDetail;
      } else if (proxy?.$store.state.clickedPicture.photo_id_str) {
        pictureDetailInfo.value = proxy.$store.state.clickedPicture;
      }

      sendPv()
    });

    const sendPv = () => {
      const params = commonPvParams({ defaultPageSource: "direct" });
      // 获取启动config
      const country = proxy.$store.state.startup.countryInfo;
      const user_id = cookies.get("user_id");
      const picture_id = pictureDetailInfo.value?.pic_id_str ?? '';

      immediateSendLog({
        type: "pv",
        name: 'SEO_PC_DETAIL_PAGE',
        value: {
          country,
          user_id,
          picture_id,
          ...params,
        },
      });
    };

    return { pictureDetailInfo};
  },
  async asyncData(ctx: any) {
    let pictureDetail = {};

    let seoData = [];
    let metaInfoData = [];
    let title = getDefaultTitle(ctx)
    let showFeed = false;
    const { query } = ctx;

    if ((process as any).server) {
      try {
        setCommonParamsCookiesInServer({
          bucket: ctx.store.state.startup.bucket,
          host: ctx.req.headers.host,
          ctx,
          countryInfo: ctx.store.state.startup.countryInfo,
      });

        const { photoId } = ctx.route?.query;
        const realPhotoId = ctx?.route?.params?.photoId || photoId;
        
        const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || ''

        // 获取google ldjson 标签
        let seoPromise = getLdJson({ ctx, params: { url } })
        // 获取meta标签
        let metaInfoPromise = getMetaInfo({ ctx, params: { url, locale: ctx.req.headers['accept-language'] } })

        // 获取图片详情
        let photoPromise = ctx.$axios.$$post(
          "overseaAPI::/rest/o/w/pic/detail",
          {
            picId: realPhotoId,
          }
        );
        let [photoRes, seoRes, metaInfoRes] = await Promise.all([photoPromise, seoPromise, metaInfoPromise]);

        if (photoRes.result === 1) {
          pictureDetail = photoRes.data;
        }

        if (seoRes.status === 200) {
          seoData = seoRes.data;
        }

        if (metaInfoRes.status === 200) {
          metaInfoData = metaInfoRes.data;
          title = extractTitleFromMetaInfo({ ctx, metaInfoData})
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      pictureDetail = ctx.store.state.clickedPicture;
      let metaInfoPromise = getMetaInfo({ 
        ctx, 
        params: { 
          url: `https://${window?.location?.host}${ctx?.route?.path}` || '',
          onlyTitle: true
        }
      })
      let metaInfoRes = await metaInfoPromise
      if (metaInfoRes.status === 200) {
        title = extractTitleFromMetaInfo({
          ctx,
          metaInfoData: metaInfoRes?.data
        })
      }
    }

    return {
      pictureDetail,
      showFeed,
      seoData,
      metaInfoData,
      title,
    };
  },
  head() {
    const head = header(this);

    return {
      ...head,
    };
  },
});
