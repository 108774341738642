import { Context } from '@nuxt/types';

export const checkSnack = (ctx?: Context) => {
  let host
  if (process.server) {
    host = ctx?.req?.headers?.host || ''
  } else {
    host = window.location.host || ''
  }
  const isSnack = host.includes('snack')
  return {
    isSnack
  }
}

export function header(that: any) {
  let title = 'Kwai'
  let appIcon = 'https://cdn-static.kwai.net/kos/s101/nlav11312/icon/kwai/2/favicon.ico';
  let appName = 'Kwai'
  let packageName = 'com.kwai.video'

  // 下面是snack的属性
  const isSnack = that.$host?.includes('snack') || location.host?.includes('snack');
  if (isSnack) {
    title = 'Snack'
    appIcon = 'https://cdn-static.kwai.net/kos/s101/nlav11312/icon/snack/2/favicon.ico';
    appName = 'SnackVideo';
    packageName = 'com.kwai.bulldog'
  }

  const meta = [
    // 服务端下发
    // /* twitter */
    // {
    //   hid: 'twitter:image',
    //   name: 'twitter:image',
    //   content: appIcon,
    // },
    // /* facebook, vk使用的header */
    // {
    //   hid: 'og:image',
    //   name: 'og:image',
    //   content: appIcon,
    // },
    { property: 'al:ios:app_name', content: appName },
    { property: 'al:android:app_name', content: appName },
    { property: 'al:android:package', content: packageName },
  ];

  // 拼接scriptArr前需要先过滤innerHTML后端下发空的case，防止拼接的innerHTML为undefined，优化GSC（google search console）爬虫
  let scriptArr = (that.seoData)?.filter((item: { innerHTML: string }) => item.innerHTML).map((item: { innerHTML: string }) => {
    return {
      ...item,
      innerHTML: `${JSON.stringify(item.innerHTML)}`
    };
  }) || [];

  return {
    htmlAttrs: {
      pc: that.$platform ? 'no' : 'yes',
      dir: that.$isRtl ? 'rtl' : 'ltr',
    },
    title: that.title || title,
    meta: [
      ...meta,
      ...that.metaInfoData,
    ],
    link: [
      {
        rel: 'image_src',
        href: appIcon,
      },
      {
        rel: 'shortcut icon',
        href: appIcon,
        type: 'image/x-icon',
      },
      {
        rel: 'apple-touch-icon-precomposed',
        href: appIcon,
      },
    ],
    script: scriptArr
  };
}

// https://docs.corp.kuaishou.com/d/home/fcAAMdFdz7E2fZBp4EFnUEV_a

// Meta标签查询
export function getMetaInfo({ ctx, params }: Record<string, any>) {
  return ctx.$axios.$$post(`overseaAPI::/rest/o/w/seo/metaInfo/getByType`, params)
}

// Script标签查询
export function getLdJson({ ctx, params }: Record<string, any>) {
  return ctx.$axios.$$post(`overseaAPI::/rest/o/w/seo/ldJson/getByType`, params)
}

/**
 * @deprecated Use `getMetaInfo` instead.
 */
export function getTitle({ ctx, params }: Record<string, any>) {
  return ctx.$axios.$$get(`overseaAPI::/rest/o/w/seo/title/getByType`, { params })
}

// 从服务端的MetaInfo接口数据中提取Title信息
export function extractTitleFromMetaInfo({ ctx, metaInfoData }: Record<string, any>) {
  const titleItem = metaInfoData?.length && metaInfoData?.find((item: { name: string; }) => item?.name === 'title');
  let title = titleItem?.content

  if (!title) {
    const { isSnack } = checkSnack(ctx)
    title = isSnack ? 'Snack' : 'Kwai'
  }

  return title;
}

// 获取兜底的页面Title
export function getDefaultTitle(ctx?: Context) {
  const { isSnack } = checkSnack(ctx)
  return isSnack ? 'Snack' : 'Kwai'
}
