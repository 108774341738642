interface HeadUrl {
    cdn: string; // Content Delivery Network domain
    url: string; // URL of the user's profile picture
}

// article详情数据结构
export interface ArticleInfo {
    kwai_id?: string;
    user_id?: string;
    article_id_str?: string;
    user_name?: string;
    headurls?: HeadUrl[];
    title?: string;
    content?: string;
    publish_timestamp?: number;
    like_count?: number;
    forward_count?: number;
    comment_count?: number;
    view_count?: number;
}

// 获取article详情信息
export function getArticleDetail({ ctx, params }: Record<string, any>) {
    return ctx.$axios.$$post(`overseaAPI::/rest/o/w/article/detail`, params);
}

// article底部按钮类型
export enum ArticleActionType {
    SHARE = 0, // 分享
    LIKE = 1, // 点赞
    COMMENT = 2, // 评论
}
