import cookies from 'js-cookie';
import { getCommonParamsByKpn } from '@/common/common-params';
import { isTiny } from '@/common/utils';

export const kwai_client_id = '21144182060-h4jroop36kitfgjd8v6bl48otppponc2.apps.googleusercontent.com';
export const snack_client_id = '1085491065542-umvch4l14qrm5np356v5ft2t4dnljpuk.apps.googleusercontent.com';
export const topick_client_id = '436181982591-kkqcs8ie8tfdt2o87h9419rfr0rvl0ij.apps.googleusercontent.com';

export enum KpnClient {
    KWAI = 'KWAI',
    KWAI_BULLDOG = 'KWAI_BULLDOG',
    KWAI_ME = 'KWAI_ME',
    TOPICK = 'TOPICK',
}

export const kpnClientMap: Record<KpnClient, string> = {
    [KpnClient.KWAI]: kwai_client_id,
    [KpnClient.KWAI_BULLDOG]: snack_client_id,
    [KpnClient.KWAI_ME]: kwai_client_id,
    [KpnClient.TOPICK]: kwai_client_id,
};

export const did = cookies.get('webDid');
export const getLoginPara = function ({ startup, kpn }: Record<string, any>) {
    const param = getCommonParamsByKpn(kpn, startup);

    const formData = new FormData();
    for (const key in param) {
        formData.append(key, param[key]);
    }
    return formData;
};

export const setCurrentUserHeadUrl = function (params: any) {
    if (params?.user?.headurls?.[0]?.url) {
        localStorage.setItem('CURRENT_USER_HEADURL', params?.user?.headurls?.[0]?.url ?? '');
    } else {
        localStorage.removeItem('CURRENT_USER_HEADURL');
    }
};

export const setLoginCookie = function (params: any) {
    // for(let i in params.user) {
    //   cookies.set(i, i === 'headurls'? JSON.stringify(params[i]): params[i])
    // }
    cookies.set('userId', params?.user?.user_id, { expires: 30, path: '' });
    cookies.set('user_id', params?.user?.user_id, { expires: 30, path: '' });
    if (!isTiny()) {
        cookies.set('did', cookies.get('webDid') || '', { expires: 30, path: '' });
    }
};
