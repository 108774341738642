
import { defineComponent } from 'vue';
import { UserApiService } from '~/api/user';

export default defineComponent({
    name: 'user-info',
    components: {},
    props: {},
    setup(props, ctx) {
        const UserApi = new UserApiService();

        const handleLogout = async () => {
            const res = await UserApi.logout();
            // 退出后刷新页面
            if (res.result === 1) {
                window.location.reload();
            }
        };
        return {
            handleLogout,
        };
    },
});
