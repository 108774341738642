/* eslint-disable import/no-extraneous-dependencies */
import { getCurrentInstance } from 'vue';
import { NuxtAxiosInstance } from 'vue/types/vue';
import { AxiosRequestConfig } from 'axios';
import { CdnWithUrl, ResponseBase } from './base';

/**
 * @zh 用户基础信息
 */
export interface UserInfo {
    following?: boolean;
    user_id?: number;
    user_name?: string;
    user_sex?: string;
    user_text?: string;
    headurl?: string;
    headurls?: CdnWithUrl[];
    verified?: boolean;
    kwai_id?: string;
    eid?: string;
}

/**
 * @zh 通过登录接口获取到的聚合过的用户信息
 */
export interface UserInfoByLogin {
    user?: UserInfo;
    user_id?: number | string;
    userId?: number | string;
    is_new?: boolean;
    user_name?: string;
    headurl?: string;
    user_sex?: string;
    user_profile_bg_url?: string;
    user_text?: string;
    /**
     * @zh 安全风控相关字段
     */
    'kwaipro.pwa_ph'?: string;
    'kwaipro.pwa_st'?: string;
    'kwaipro.pwa.at'?: string;
}

export interface ReportFirebaseTokenRequest {
    push_token?: string;
    timestamp?: number;
    domain?: string;
    url?: string;
    ua?: string;
    web_did?: string;
    kwai_id?: string;
    user_id_str?: string;
    kpn?: string;
    session_id?: string;
    countryInfo?: string;
    bucket?: string;
    extra_params?: Record<string, any>;
}

type GReportFirebaseTokenResponse = ResponseBase;

interface GetUserInfoResponse extends ResponseBase {
    user?: UserInfo;
    result?: number;
}

interface GetLoginConfigRequest {
    appId: string;
}

interface GetLoginConfigResponse extends ResponseBase {
    loginChannel?: string[];
    result: number;
}

interface ThirdPlatformLoginRequest {
    kpn: string;
    startup: string;
}

interface ThirdPlatformLoginResponse extends ResponseBase, UserInfoByLogin {
    result: number;
}

interface SetCookieRequest {
    authToken: string;
    sid: string;
    followUrl: string;
}

interface LogoutResponse extends ResponseBase {
    result: number;
}

export class UserApiService {
    private $axios?: NuxtAxiosInstance;

    constructor() {
        const { proxy } = getCurrentInstance() || {};
        this.$axios = proxy?.$axios;
    }

    async getUserInfo() {
        const res = await this.$axios?.$$post?.('/rest/o/w/user/login/visitor/info');
        return res as GetUserInfoResponse;
    }

    async getLoginConfig(data: GetLoginConfigRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$get?.(`/rest/o/w/login/passport/webLoginConfig`, {
            ...config,
            params: data,
        });
        return res as GetLoginConfigResponse;
    }

    async thirdPlatformLogin(
        accessToken: string,
        platform: string,
        did: string,
        appId: string | undefined,
        data: ThirdPlatformLoginRequest,
        config?: AxiosRequestConfig
    ) {
        const res = await this.$axios?.$$post?.<ThirdPlatformLoginResponse>(
            `/rest/o/w/user/thirdPlatformLogin?access_token=${accessToken}&platform=${platform}&did=${did}${
                platform === 'facebook_kwai' ? `&appId=${appId}` : ''
            }`,
            {
                ...config,
                params: data,
            }
        );
        return res;
    }

        async reportFirebaseToken(data: ReportFirebaseTokenRequest, config?: AxiosRequestConfig) {
        const res = await this.$axios?.$$post?.<GReportFirebaseTokenResponse>('/rest/o/w/pwa/token/report', data, config);
        return res;
    }

    async setCookie(data: SetCookieRequest, config?: AxiosRequestConfig) {
        await this.$axios?.$$post?.(`/rest/o/w/user/login/setCookie`, data, config);
    }

    async logout() {
        const res = await this.$axios?.$$get?.(`/rest/o/w/account/logout`);
        return res as LogoutResponse;
    }
}
