import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _00a251c5 = () => interopDefault(import('../pages/foryou/index.vue' /* webpackChunkName: "pages/foryou/index" */))
const _dfbf23d8 = () => interopDefault(import('../pages/article/components/article-action/index.vue' /* webpackChunkName: "pages/article/components/article-action/index" */))
const _1c81626f = () => interopDefault(import('../pages/article/components/article-detail/index.vue' /* webpackChunkName: "pages/article/components/article-detail/index" */))
const _544cfedc = () => interopDefault(import('../pages/article/models/main.ts' /* webpackChunkName: "pages/article/models/main" */))
const _5de81bdc = () => interopDefault(import('../pages/discover/components/card/index.vue' /* webpackChunkName: "pages/discover/components/card/index" */))
const _909ad1e6 = () => interopDefault(import('../pages/discover/components/content/index.vue' /* webpackChunkName: "pages/discover/components/content/index" */))
const _468d39a1 = () => interopDefault(import('../pages/discover/components/image-category-card/index.vue' /* webpackChunkName: "pages/discover/components/image-category-card/index" */))
const _80619262 = () => interopDefault(import('../pages/discover/components/video/index.vue' /* webpackChunkName: "pages/discover/components/video/index" */))
const _72eecc78 = () => interopDefault(import('../pages/embed/components/video.vue' /* webpackChunkName: "pages/embed/components/video" */))
const _02cf0df5 = () => interopDefault(import('../pages/foryou/components/load/index.vue' /* webpackChunkName: "pages/foryou/components/load/index" */))
const _38ae0a8a = () => interopDefault(import('../pages/playlist/components/card/index.vue' /* webpackChunkName: "pages/playlist/components/card/index" */))
const _c2ebd26e = () => interopDefault(import('../pages/playlist/components/list/index.vue' /* webpackChunkName: "pages/playlist/components/list/index" */))
const _8e8700a0 = () => interopDefault(import('../pages/playlist/hooks/useList.ts' /* webpackChunkName: "pages/playlist/hooks/useList" */))
const _202c5c74 = () => interopDefault(import('../pages/topic/components/load/index.vue' /* webpackChunkName: "pages/topic/components/load/index" */))
const _3acc3b1f = () => interopDefault(import('../pages/topic/components/picture-card/index.vue' /* webpackChunkName: "pages/topic/components/picture-card/index" */))
const _026fbe0f = () => interopDefault(import('../pages/topic/components/tyml-card/index.vue' /* webpackChunkName: "pages/topic/components/tyml-card/index" */))
const _c149743e = () => interopDefault(import('../pages/topic/components/utils.ts' /* webpackChunkName: "pages/topic/components/utils" */))
const _013c4742 = () => interopDefault(import('../pages/topic/components/video-card/index.vue' /* webpackChunkName: "pages/topic/components/video-card/index" */))
const _f018445e = () => interopDefault(import('../pages/topic/components/water-fall/index.vue' /* webpackChunkName: "pages/topic/components/water-fall/index" */))
const _42e6e324 = () => interopDefault(import('../pages/user/components/header.vue' /* webpackChunkName: "pages/user/components/header" */))
const _bd82f1aa = () => interopDefault(import('../pages/user/components/loading.vue' /* webpackChunkName: "pages/user/components/loading" */))
const _002e6caa = () => interopDefault(import('../pages/user/components/video.vue' /* webpackChunkName: "pages/user/components/video" */))
const _8990c272 = () => interopDefault(import('../pages/user/hooks/useInfo.ts' /* webpackChunkName: "pages/user/hooks/useInfo" */))
const _521b8cd7 = () => interopDefault(import('../pages/user/hooks/useList.ts' /* webpackChunkName: "pages/user/hooks/useList" */))
const _76776db7 = () => interopDefault(import('../pages/discover/components/content/loading.vue' /* webpackChunkName: "pages/discover/components/content/loading" */))
const _19a4d004 = () => interopDefault(import('../pages/discover/components/content/notFound.vue' /* webpackChunkName: "pages/discover/components/content/notFound" */))
const _6b24abe0 = () => interopDefault(import('../pages/discover/components/content/user.vue' /* webpackChunkName: "pages/discover/components/content/user" */))
const _31f6dabf = () => interopDefault(import('../pages/article/_content.vue' /* webpackChunkName: "pages/article/_content" */))
const _35d2d644 = () => interopDefault(import('../pages/discover/_content.vue' /* webpackChunkName: "pages/discover/_content" */))
const _feaef9a0 = () => interopDefault(import('../pages/embed/_photoId.vue' /* webpackChunkName: "pages/embed/_photoId" */))
const _31508c2b = () => interopDefault(import('../pages/picture/_photoId.vue' /* webpackChunkName: "pages/picture/_photoId" */))
const _0efb41ec = () => interopDefault(import('../pages/playlist/_albumId.vue' /* webpackChunkName: "pages/playlist/_albumId" */))
const _3b398c26 = () => interopDefault(import('../pages/topic/_content.vue' /* webpackChunkName: "pages/topic/_content" */))
const _e5817aa6 = () => interopDefault(import('../pages/user/_userId.vue' /* webpackChunkName: "pages/user/_userId" */))
const _79471664 = () => interopDefault(import('../pages/video/_photoId.vue' /* webpackChunkName: "pages/video/_photoId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foryou",
    component: _00a251c5,
    name: "foryou"
  }, {
    path: "/article/components/article-action",
    component: _dfbf23d8,
    name: "article-components-article-action"
  }, {
    path: "/article/components/article-detail",
    component: _1c81626f,
    name: "article-components-article-detail"
  }, {
    path: "/article/models/main",
    component: _544cfedc,
    name: "article-models-main"
  }, {
    path: "/discover/components/card",
    component: _5de81bdc,
    name: "discover-components-card"
  }, {
    path: "/discover/components/content",
    component: _909ad1e6,
    name: "discover-components-content"
  }, {
    path: "/discover/components/image-category-card",
    component: _468d39a1,
    name: "discover-components-image-category-card"
  }, {
    path: "/discover/components/video",
    component: _80619262,
    name: "discover-components-video"
  }, {
    path: "/embed/components/video",
    component: _72eecc78,
    name: "embed-components-video"
  }, {
    path: "/foryou/components/load",
    component: _02cf0df5,
    name: "foryou-components-load"
  }, {
    path: "/playlist/components/card",
    component: _38ae0a8a,
    name: "playlist-components-card"
  }, {
    path: "/playlist/components/list",
    component: _c2ebd26e,
    name: "playlist-components-list"
  }, {
    path: "/playlist/hooks/useList",
    component: _8e8700a0,
    name: "playlist-hooks-useList"
  }, {
    path: "/topic/components/load",
    component: _202c5c74,
    name: "topic-components-load"
  }, {
    path: "/topic/components/picture-card",
    component: _3acc3b1f,
    name: "topic-components-picture-card"
  }, {
    path: "/topic/components/tyml-card",
    component: _026fbe0f,
    name: "topic-components-tyml-card"
  }, {
    path: "/topic/components/utils",
    component: _c149743e,
    name: "topic-components-utils"
  }, {
    path: "/topic/components/video-card",
    component: _013c4742,
    name: "topic-components-video-card"
  }, {
    path: "/topic/components/water-fall",
    component: _f018445e,
    name: "topic-components-water-fall"
  }, {
    path: "/user/components/header",
    component: _42e6e324,
    name: "user-components-header"
  }, {
    path: "/user/components/loading",
    component: _bd82f1aa,
    name: "user-components-loading"
  }, {
    path: "/user/components/video",
    component: _002e6caa,
    name: "user-components-video"
  }, {
    path: "/user/hooks/useInfo",
    component: _8990c272,
    name: "user-hooks-useInfo"
  }, {
    path: "/user/hooks/useList",
    component: _521b8cd7,
    name: "user-hooks-useList"
  }, {
    path: "/discover/components/content/loading",
    component: _76776db7,
    name: "discover-components-content-loading"
  }, {
    path: "/discover/components/content/notFound",
    component: _19a4d004,
    name: "discover-components-content-notFound"
  }, {
    path: "/discover/components/content/user",
    component: _6b24abe0,
    name: "discover-components-content-user"
  }, {
    path: "/article/:content?",
    component: _31f6dabf,
    name: "article-content"
  }, {
    path: "/discover/:content?",
    component: _35d2d644,
    name: "discover-content"
  }, {
    path: "/embed/:photoId?",
    component: _feaef9a0,
    name: "embed-photoId"
  }, {
    path: "/picture/:photoId?",
    component: _31508c2b,
    name: "picture-photoId"
  }, {
    path: "/playlist/:albumId?",
    component: _0efb41ec,
    name: "playlist-albumId"
  }, {
    path: "/topic/:content?",
    component: _3b398c26,
    name: "topic-content"
  }, {
    path: "/user/:userId?",
    component: _e5817aa6,
    name: "user-userId"
  }, {
    path: "/video/:photoId?",
    component: _79471664,
    name: "video-photoId"
  }, {
    path: "/@*/playlist/:albumId",
    component: _0efb41ec,
    name: "playlist-detail"
  }, {
    path: "/@*/video/:photoId",
    component: _79471664,
    name: "video-detail"
  }, {
    path: "/@*/picture/:photoId",
    component: _31508c2b,
    name: "picture-detail"
  }, {
    path: "/@*",
    component: _e5817aa6,
    name: "profile"
  }, {
    path: "/",
    component: _00a251c5,
    name: "foryou"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
