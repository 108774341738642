
import { defineComponent, onMounted, getCurrentInstance, ref, watch, onActivated, onDeactivated, nextTick, computed } from 'vue';
import cookies from 'js-cookie';
import Content from './components/content/index.vue';
import { sendLog, immediateSendLog, getKwaiLog } from '@/common/radar';
import { commonPvParams, repalacePath, getQuery, checkSnack, FeedType } from '@/common/utils';
import { getMetaInfo, header, extractTitleFromMetaInfo, getDefaultTitle, getLdJson } from '@/common/seo';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import VideoSwiper from '@/components/VideoSwiper/index.vue';
import Card from './components/card/index.vue';
import Load from './components/content/loading.vue';
import NotFound from './components/content/notFound.vue';

const ForbidWordRegExp =
    /[\u4e00-\u9fa5]|(https?:\/\/.*)|(\.(com|net|org|co|io|gov|edu|biz|info|me|mil|tv|name|cc|app|dev|cloud|shop|site|tech|online|pro|win|cn))/i;

export default defineComponent({
    name: 'discover-page',
    components: {
        Content,
        VideoSwiper,
        Card,
        Load,
        NotFound,
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as { proxy: Record<string, any> };
        const { isSnack } = checkSnack();
        const fmp_time = Date.now();

        // Discover页 "Video + 图文" 混排，
        // 在Discover页展示Video和图文两种类型卡片；详情页（上下滑）仅展示
        // 使用feedsWithImgCard集合给Discover页使用，feed集合给详情页（上下滑）使用
        const feed = ref<any>([]); // 搜索结果的Feed集合，仅包括Video类型，图文过滤
        const feedsWithImgCard = ref<any>([]); // 搜索结果的Feed集合，包括Video类型 + 图文类型
        const users = ref<any>([]);
        const cursorIndex = ref('');
        const tabName = ref('');
        const searchWord = ref('');
        const isSearching = ref(false);
        const isShowNotFound = ref<boolean>(false);
        const index = ref(0);

        const isCard = ref(proxy.$store.state.startup?.searchUpAndDownSlide === 'single_column');

        const isFirstEnter = ref(true);

        const isShow = ref(false);

        const selectedSlide = ref<string>('For You');

        const prevPath = ref('');

        const isPause = ref(false);

        //  是否有更多
        const isHasMore = ref<string>('has_more');

        const changeSelectedSlide = (selected: string) => {
            selectedSlide.value = selected;
        };
        const getData = async ({ count = 10, fromUser = false }) => {
            const query = (proxy?.$route?.query || {}) as { from_user?: 'true' | 'false' };
            if (isSearching.value || cursorIndex.value === 'no_more') return;
            try {
                isSearching.value = true;
                isShowNotFound.value = false;
                const { feeds, result, userList, cursor, pcursor } = await proxy.$axios.$$post(`overseaAPI::/rest/o/w/pc/feed/search`, {
                    tabName: tabName.value,
                    searchWord: encodeURIComponent(searchWord.value?.trim()?.replace(/\s/g, '-')),
                    pcursor: cursorIndex.value,
                    count,
                    fromUser: query.from_user === 'true' ? true : fromUser,
                });
                if (result === 1) {
                    isSearching.value = false;
                    // feedsWithImgCard存储所有feed集合，包括Video+图文
                    // feed仅存储Video集合
                    const filteredFeeds = feeds?.filter((item: { type?: number }) => item?.type !== FeedType.PICTURE_CATEGORY_CARD);
                    filteredFeeds?.length && feed.value.push(...filteredFeeds);
                    if (getQuery()?.from_user !== 'true') {
                        feeds?.length && feedsWithImgCard.value.push(...feeds);
                    }

                    // filterPictureIndex
                    let filterPictureIndex = 0;
                    feedsWithImgCard.value.forEach((item: any) => {
                        if (item.type !== FeedType.PICTURE_CATEGORY_CARD) {
                            item.filterPictureIndex = filterPictureIndex;
                            filterPictureIndex++;
                        }
                    });

                    userList?.length && users.value.push(...userList);
                    cursorIndex.value = cursor;
                    if (pcursor === 'no_more' || cursor === 'no_more') {
                        isHasMore.value = 'no_more';
                    } else {
                        isHasMore.value = 'has_more';
                    }
                } else {
                    isSearching.value = false;
                }

                if (feedsWithImgCard.value.length === 0) {
                    isShowNotFound.value = true;
                }

                //  判断内容是否撑满页面
                if (feeds.length) {
                    nextTick(() => {
                        const searchContent: HTMLElement = document.getElementById('search-content') as any;
                        if (!searchContent) return;
                        const windowHeight = searchContent?.clientHeight || document?.body?.clientHeight;
                        const scrollHeight = searchContent?.scrollHeight || document?.body?.scrollHeight;
                        if (windowHeight + 100 >= scrollHeight) getData({ count: 10 });
                    });
                }
            } catch (error) {
                if (feedsWithImgCard.value.length === 0) {
                    isShowNotFound.value = true;
                }
                console.log(error);
                isSearching.value = false;
            }
        };

        const sendPv = () => {
            const params = commonPvParams({ defaultPageSource: 'search' });
            // 获取启动config
            const country = proxy.$store.state.startup.countryInfo;
            const user_id = cookies.get('user_id');
            immediateSendLog({
                type: 'pv',
                name: 'SEO_PC_SEARCH_RESULT_PAGE',
                value: {
                    country,
                    user_id,
                    search_word: searchWord.value,
                    ...params,
                },
            });
        };

        const handleMenuClick = async (name: string) => {
            feed.value = [];
            feedsWithImgCard.value = [];
            users.value = [];
            cursorIndex.value = '';
            tabName.value = name;
            selectedSlide.value = name || 'For You';
            proxy.$store.commit('setSearchValue', '');
            if (!name) {
                proxy.$router.push({ path: `/foryou`, query: { ...getQuery(), page_source: 'discover', from_user: '' } });
                return;
            }
            await getData({ count: 15 });
        };

        const handleLoadMore = () => {
            getData({ count: 10 });
        };

        const onVideoClick = (item: any, slideIndex: number) => {
            prevPath.value = location.pathname;
            isShow.value = true;
            index.value = slideIndex;
            isPause.value = true;
            repalacePath(`/@${item?.kwai_id}/video/${item?.photo_id_str}`);
        };

        const initData = async (fromUser: boolean) => {
            feed.value = [];
            feedsWithImgCard.value = [];
            users.value = [];
            cursorIndex.value = '';
            searchWord.value = proxy.$store.state.searchValue;
            if (searchWord.value) {
                tabName.value = '';
                selectedSlide.value = searchWord.value;
            }
            await getData({ count: 14, fromUser });
            sendPv();
        };

        const handleReturn = (number: number) => {
            isShow.value = false;
            isPause.value = false;
            // 这里的number指的是视频在详情页中的index
            // 详情页返回后，如果discover包含图文卡片，需要计算出加上图文卡片后的真实index
            // 这样才会定位到photo真实的位置
            let indexFound = feedsWithImgCard.value.findIndex((photoItem: any) => photoItem?.filterPictureIndex === number);
            indexFound = indexFound === -1 ? number : indexFound;
            indexFound = indexFound < feedsWithImgCard.value.length ? indexFound : 0;
            index.value = indexFound;

            repalacePath(`${prevPath.value}`);
        };

        const timeout = ref<any>(null);
        const playVideo = ref(0);
        const handleScroll = (e: Event) => {
            if (!isCard.value) return;
            if (timeout.value) clearTimeout(timeout.value);
            timeout.value = setTimeout(() => {
                const content: HTMLElement = document.getElementById('search-content') as any;
                const index = Math.round(content?.scrollTop / 794);
                if (index === playVideo.value) return;
                playVideo.value = index;
            }, 300);
        };

        watch(
            () => proxy.$store.state.searchValue,
            (val: string) => {
                initData(true);
            }
        );

        const scrollHeight = ref(0);

        onDeactivated(() => {
            const searchContent: HTMLElement = document.getElementById('search-content') as any;
            scrollHeight.value = searchContent.scrollTop;
            isFirstEnter.value = false;
        });

        onActivated(() => {
            if (tabName.value !== getQuery()?.tabName && !isFirstEnter.value) {
                handleMenuClick(getQuery()?.tabName);
            } else {
                const searchContent: HTMLElement = document.getElementById('search-content') as any;
                searchContent?.scrollTo(0, scrollHeight.value);
            }
        });

        // index指的是选定的photo在整个feeds（视频+图文）中的位置
        // filterPictureIndex指的是选定的photo在 视频feeds集合（过滤图文）中的位置
        const filterPictureIndex = computed(() => {
            const indexFound = feedsWithImgCard.value[index.value]?.filterPictureIndex ?? index.value;
            return indexFound < feedsWithImgCard.value.length ? indexFound : 0;
        });

        onMounted(() => {
            const webLogger = getKwaiLog();
            try {
                webLogger?.plugins?.radar?.fmp(fmp_time);
            } catch (error) {
                console.error(error);
            }

            if (getQuery()?.tabName !== 'undefined' && getQuery()?.tabName) {
                tabName.value = getQuery()?.tabName || '';
                selectedSlide.value = getQuery()?.tabName || 'For You';
            }

            if (proxy.$route.params) {
                proxy.$store.commit('setSearchValue', proxy.$route.params?.content?.replace(/(-)/g, ' '));
            }

            initData(false);
            // document.addEventListener('visibilitychange', () => {
            //    const pageVisibility = document.visibilityState;
            //    // 页面变为不可见时触发
            //    if (pageVisibility === 'hidden') {
            //        sendPv('hidden')
            //    }
            // });
            // window.onbeforeunload = window.onunload = function() {
            //    sendPv('leave')
            // };
        });

        return {
            feed,
            feedsWithImgCard,
            users,
            getData,
            handleMenuClick,
            isSearching,
            searchWord,
            isShowNotFound,
            selectedSlide,
            changeSelectedSlide,
            isHasMore,
            handleLoadMore,
            metaInfoData: [],
            onVideoClick,
            isShow,
            handleReturn,
            index,
            isPause,
            isCard,
            isSnack,
            handleScroll,
            playVideo,
            filterPictureIndex,
        };
    },
    async asyncData(ctx) {
        let leftTablist: { key: string; element: string }[] = [];
        let seoData = [];
        // let videoFeedsData = {} as any
        let isServerFetched = false; // 前端切换路由时 asyncData也会调用 判断是否在服务端获取过数据
        let metaInfoData = [];
        let title = getDefaultTitle(ctx);
        if (!ctx.route.query?.tabName && !ctx.route.params?.content) return ctx.redirect('/foryou');
        if ((process as any).server) {
            //  侧边栏获取
            try {
                const leftTabListRes = JSON.parse(await ctx.app.$kconf.getValue('overseaServer.share.pcLeftTabList'));
                for (const key in leftTabListRes) {
                    if (Object.prototype.hasOwnProperty.call(leftTabListRes, key)) {
                        const element = leftTabListRes[key];
                        leftTablist.push({ key, element });
                    }
                }
            } catch (error) {
                console.log(error);
            }

            // let feeds = [] as Array<Record<string, any>>

            // seo数据
            try {
                // let metaInfoPromise = getMetaInfo({ ctx, params: { type: 'SEARCH', id: kwaiId, locale: ctx.req.headers['accept-language'] } })

                setCommonParamsCookiesInServer({
                    bucket: ctx.store.state.startup.bucket,
                    host: ctx.req.headers.host,
                    ctx,
                    countryInfo: ctx.store.state.startup.countryInfo,
                });

                isServerFetched = true;

                const content = ctx?.route?.params?.content || '';
                const isForbidWord = ForbidWordRegExp.test(content);
                if (isForbidWord) return ctx.redirect(301, '/foryou');
                ctx.store.commit('setSearchValue', content?.replace(/(-)/g, ' '));

                const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || '';
                // 获取google ldjson 标签
                const seoPromise = getLdJson({ ctx, params: { url } });
                // 获取metaInfo
                const metaInfoPromise = getMetaInfo({ ctx, params: { url, locale: ctx.req.headers['accept-language'] } });

                // let feedPromise = ctx.$axios.$$post('overseaAPI::/rest/o/w/pwa/feed/search', {
                //   searchWord: encodeURIComponent(content),
                //   type: 'video',
                //   beforePage: '',
                //   count: 8 // 单次请求的post数量
                // })

                // let [seoRes, videoFeedRes, metaInfoRes, titleRes] = await Promise.all([seoPromise, feedPromise, metaInfoPromise, titlePromise])

                const [seoRes, metaInfoRes] = await Promise.all([seoPromise, metaInfoPromise]);
                if (seoRes.status === 200) {
                    seoData = seoRes.data;
                }

                if (metaInfoRes.status === 200) {
                    metaInfoData = metaInfoRes.data;
                    title = extractTitleFromMetaInfo({ ctx, metaInfoData });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            leftTablist = [
                {
                    key: 'Hot',
                    element: 'hot',
                },
                {
                    key: 'Film&TV',
                    element: 'film',
                },
                {
                    key: 'Comedy',
                    element: 'comedy',
                },
                {
                    key: 'Liferecord',
                    element: 'liferecord',
                },
                {
                    key: 'News',
                    element: 'news',
                },
                {
                    key: 'TrendingStyles',
                    element: 'trendingStyles',
                },
                {
                    key: 'Sports',
                    element: 'sports',
                },
                {
                    key: 'Music',
                    element: 'music',
                },
            ];
        }

        return {
            leftTablist,
            seoData,
            // videoFeedsData,
            isServerFetched,
            metaInfoData,
            title,
        };
    },
    head() {
        const head = header(this);

        return {
            ...head,
        };
    },
});
