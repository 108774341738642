
import cookies from 'js-cookie';
import { defineComponent, watch, getCurrentInstance, onMounted, ref, onUnmounted, onActivated } from 'vue';
import { getUrlKwaiId, commonPvParams, repalacePath, getQuery, checkSnack } from '@/common/utils';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import { immediateSendLog, getKwaiLog, sendLog } from '@/common/radar';
import { getMetaInfo, header, extractTitleFromMetaInfo, getDefaultTitle, getLdJson } from '@/common/seo';
import Header from './components/header.vue';
import VideoBox from './components/video.vue';
import VideoSwiper from '@/components/VideoSwiper/index.vue';
import Loading from './components/loading.vue';
import { useList } from './hooks/useList';
import { useInfo } from './hooks/useInfo';

export default defineComponent({
    components: {
        Header,
        VideoBox,
        VideoSwiper,
        Loading,
    },
    // layout: 'noFooterLayout',
    setup() {
        const fmp_time = Date.now();
        const { proxy } = getCurrentInstance() as { proxy: Record<string, any> };

        const { isSnack } = checkSnack();
        // const feeds = ref(proxy?.feedsData?.feeds || []);

        const {
            profile,
            isFollowing,
            isFollowed,
            followRequesting,
            isBlockedByOwner,
            isBlocked,
            isPrivacyUser,
            userName,
            feeds,
            ownerCount,
            userId,
            isPoi,
            refreshInfo,
            setUserProfile,
            getUserInfo,
        } = useInfo();

        // list hooks
        const { cursor, finished, loading, postNum, postText, loadMorePost, getUserFeed } = useList({
            feeds,
            ownerCount,
            isBlockedByOwner,
            isBlocked,
        });

        const isShow = ref(false);

        const showShare = ref(false);
        const isClickShare = ref(false);

        const slideIndex = ref(0);

        const isPause = ref(false);

        const handleClickFollow = () => {
            proxy.$bus.emit('show-model', true);
            sendLog({
                type: 'click',
                name: 'USER_CARD',
                value: {
                    button_type: 'follow',
                },
            });
        };

        const handleShare = () => {
            showShare.value = false;
        };

        const initData = async () => {
            loading.value = true;

            const [profileRes, feedRes] = await Promise.all([getUserInfo(), getUserFeed()]);
            loading.value = false;

            // 隐私用户
            if (profileRes.result === 1047232028 || feedRes.result === 1047232028) {
                isPrivacyUser.value = true;
            }

            if (profileRes.result === 1) {
                const { userProfile } = profileRes;
                profile.value = {
                    ...userProfile?.profile,
                };
                ownerCount.value = {
                    ...userProfile?.ownerCount,
                };
                setUserProfile(userProfile);
            }
            if (feedRes.result === 1) {
                feeds.value = feedRes?.feeds;
                cursor.value = feedRes?.cursor;
            }
        };

        const sendPv = () => {
            const params = commonPvParams({ defaultPageSource: 'search' });
            // 获取启动config
            const country = proxy.$store.state.startup.countryInfo;
            const user_id = cookies.get('user_id');
            const referrer = document?.referrer;
            immediateSendLog({
                type: 'pv',
                name: 'PC_PROFILE_PAGE',
                value: {
                    country,
                    user_id,
                    ...params,
                },
            });
        };

        const handleVideoDetail = (index: number) => {
            slideIndex.value = index;
            isShow.value = true;
            isPause.value = true;
            const path = `/@${(feeds.value as any)?.[slideIndex.value]?.kwai_id}/video/${(feeds.value as any)?.[slideIndex.value]?.photo_id_str}`;

            repalacePath(path);
        };

        const handleReturn = () => {
            isShow.value = false;
            isPause.value = false;
            repalacePath(`/@${(feeds.value as any)?.[slideIndex.value]?.kwai_id}`);
        };

        const handleMenuClick = (key: string) => {
            if (!key) proxy.$router.push({ path: `/foryou`, query: { ...getQuery(), page_source: 'profile' } });
            else proxy.$router.push({ path: `/discover`, query: { ...getQuery(), page_source: 'profile', tabName: key } });
        };

        const handleShareIcon = () => {
            console.log('handleShareIcon');
            showShare.value = true;
            if (showShare.value) isClickShare.value = true;
            else isClickShare.value = false;
        };

        const timeoutOver = ref<any>(null);
        const timeoutOut = ref<any>(null);

        const onMouseOver = () => {
            console.log('onMouseOver');
            clearTimeout(timeoutOut.value);
            if (showShare.value) return;
            timeoutOver.value = setTimeout(() => {
                showShare.value = true;
            }, 0);
        };

        const onMouseOut = () => {
            console.log('onMouseOut');
            clearTimeout(timeoutOver.value);
            if (!showShare.value || isClickShare.value) return;
            timeoutOut.value = setTimeout(() => {
                console.log(234, 'onMouseOut', isClickShare.value);
                if (isClickShare.value) return;
                showShare.value = false;
            }, 500);
        };

        onMounted(() => {
            const webLogger = getKwaiLog();
            proxy.$bus.emit('isLoad', false);
            try {
                webLogger?.plugins?.radar?.fmp(fmp_time);
            } catch (error) {
                console.error(error);
            }
            proxy.$bus.on('sendPv', sendPv);
            sendPv();
            const profileServerData = proxy?.userProfile?.profile;
            const ownerCountServerData = proxy?.userProfile?.ownerCount;
            const feedsServerData = proxy?.feedsData?.feeds;
            const pcursorServerData = proxy?.feedsData?.cursor;

            if (profileServerData) {
                profile.value = profileServerData;
            }

            setUserProfile(proxy?.followType);

            if (ownerCountServerData) {
                ownerCount.value = ownerCountServerData;
            }
            if (feedsServerData) {
                feeds.value = feedsServerData;
                cursor.value = pcursorServerData;
            }

            if (!proxy.isServerFetched) {
                initData();
            }

            const searchContent: HTMLElement = document.getElementById('main-container') as any;
            searchContent.onscroll = () => {
                // 滚动条滚动时，距离顶部的距离
                const scrollTop = searchContent.scrollTop || document.body.scrollTop;
                // windowHeight是可视区的高度
                const windowHeight = searchContent.clientHeight || document.body.clientHeight;
                // scrollHeight是滚动条的总高度
                const scrollHeight = searchContent.scrollHeight || document.body.scrollHeight;

                if (windowHeight >= scrollHeight) {
                    loadMorePost();
                }
                // 滚动条到底部的条件
                if (scrollTop + windowHeight + 50 >= scrollHeight) {
                    loadMorePost();
                }
            };
        });

        onUnmounted(() => {
            proxy.$bus.off('sendPv', sendPv);
        });

        // onActivated(() => {
        //   proxy.$bus.emit('isLoad', false)
        // })

        return {
            proxy,
            feeds,
            isShow,
            showShare,
            isClickShare,
            handleShare,
            slideIndex,
            handleVideoDetail,
            handleClickFollow,
            loading,
            handleReturn,
            isPrivacyUser,
            cursor,
            handleMenuClick,
            profile,
            ownerCount,
            isSnack,
            loadMorePost,
            handleShareIcon,
            onMouseOver,
            onMouseOut,
            isPause,
        };
    },
    async asyncData(ctx: any) {
        let userProfile = {};
        let feedsData = {};
        let seoData = [];
        let metaInfoData = [];
        let title = getDefaultTitle(ctx);
        const followType = {
            isFollowing: false,
            isBlocked: false,
            isOwner: false,
            followRequesting: false,
            isFollowed: false,
            isBlockedByOwner: false,
            isPrivacyUser: false,
        };
        const kwaiId = getUrlKwaiId(ctx);

        let isServerFetched = false; // 前端切换路由时 asyncData也会调用 判断是否在服务端获取过数据
        if (process.server) {
            const { mcc } = ctx.route.query;
            isServerFetched = true;
            // 预请求接口
            try {
                const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || '';
                let pidStrList = [];

                // 获取meta info信息
                const metaInfoPromise = getMetaInfo({ ctx, params: { url, locale: ctx.req.headers['accept-language'] } });

                setCommonParamsCookiesInServer({
                    bucket: ctx.store.state.startup.bucket,
                    host: ctx.req.headers.host,
                    ctx,
                    countryInfo: ctx.store.state.startup.countryInfo,
                });

                const profilePromise = ctx.$axios.$$post('overseaAPI::/rest/o/w/pc/profile', {
                    // userId: ctx.route.params.userId,
                    userId: kwaiId,
                    mcc,
                    deviceId: '',
                    fromPhoto: false,
                });

                const feedPromise = ctx.$axios.$$post('overseaAPI::/rest/o/w/pc/feed/profile', {
                    // userId: ctx.route.params.userId,
                    userId: kwaiId,
                    beforePage: '',
                    fromPage: 'PWA_PROFILE',
                    count: 20, // 单次请求的post数量
                });

                const [profileRes, feedRes, metaInfoRes] = await Promise.all([profilePromise, feedPromise, metaInfoPromise]);

                if (metaInfoRes.status === 200) {
                    metaInfoData = metaInfoRes.data;
                    title = extractTitleFromMetaInfo({ ctx, metaInfoData });
                }

                // 隐私用户
                if (profileRes.result === 1047232028 || feedRes.result === 1047232028) {
                    followType.isPrivacyUser = true;
                }

                if (profileRes.result === 1) {
                    userProfile = {
                        ...profileRes.userProfile,
                    };

                    followType.isFollowing = profileRes?.userProfile?.isFollowing;
                    followType.isBlocked = profileRes?.userProfile?.isBlocked;
                    followType.isOwner = profileRes?.userProfile?.isOwner;
                    followType.followRequesting = profileRes?.userProfile?.followRequesting;
                    followType.isFollowed = profileRes?.userProfile?.isFollowed;
                    followType.isBlockedByOwner = profileRes?.userProfile?.isBlockedByOwner;
                }
                // console.log('feedRes', feedRes)
                if (feedRes.result === 1) {
                    feedsData = {
                        feeds: feedRes?.feeds,
                        cursor: feedRes?.cursor,
                        albums: feedRes?.albums,
                        albumCount: feedRes?.albumCount,
                    };
                    pidStrList = feedRes?.feeds?.map((item: { photo_id_str?: any }) => item?.photo_id_str)?.filter((pid: any) => pid !== undefined);
                }

                // 获取google ldjson 标签
                const seoPromise = getLdJson({ ctx, params: { url, pidStrList: pidStrList || '' } });

                const seoRes = await seoPromise;
                if (seoRes.status === 200) {
                    // seoData = JSON.parse(seoRes.data)
                    seoData = seoRes.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        return {
            userProfile,
            feedsData,
            followType,
            isServerFetched,
            seoData,
            metaInfoData,
            title,
        };
    },
    head() {
        const head = header(this);
        return {
            ...head,
        };
    },
});
