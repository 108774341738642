import { render, staticRenderFns } from "./_photoId.vue?vue&type=template&id=1ad9c50d&scoped=true"
import script from "./_photoId.vue?vue&type=script&lang=ts"
export * from "./_photoId.vue?vue&type=script&lang=ts"
import style1 from "./_photoId.vue?vue&type=style&index=1&id=1ad9c50d&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad9c50d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoSwiper: require('/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/components/VideoSwiper/index.vue').default})
