// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./imgs/searchEmpty.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page-container[data-v-2e9cbc91] {\n  width: 100%;\n  height: 100%;\n  display: flex;\n}\n.page-container .topic-container[data-v-2e9cbc91] {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow-y: scroll;\n  align-items: center;\n}\n.page-container .topic-container .topic-content[data-v-2e9cbc91] {\n  width: calc(100% - 10.41667vw);\n  display: flex;\n  flex-direction: column;\n}\n.page-container .topic-container .topic-content .empty-img[data-v-2e9cbc91] {\n  height: 5vw;\n  width: 5vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/100% no-repeat;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.page-container .topic-container .topic-content .has-no-more[data-v-2e9cbc91] {\n  width: 100%;\n  height: 5.20833vw;\n  z-index: 100;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  font-size: 0.9375vw;\n  font-weight: 400;\n}\n.page-container .topic-container .scroll-top[data-v-2e9cbc91] {\n  width: 3.125vw;\n  height: 3.125vw;\n  right: 1.5625vw;\n  bottom: 1.5625vw;\n  z-index: 300;\n  position: absolute;\n  border-radius: 50%;\n  cursor: pointer;\n}\n.page-container .topic-container .up[data-v-2e9cbc91] {\n  width: 3.125vw;\n  height: 3.125vw;\n}\n.page-container .video-swiper[data-v-2e9cbc91] {\n  position: absolute;\n  top: 0;\n  background: #000;\n  z-index: 999;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
