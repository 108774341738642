
import { defineComponent, getCurrentInstance, onMounted, ref, watch, nextTick, onActivated, onDeactivated, PropType } from 'vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import AuthorInfo from '@/components/DetailSideInfoComponents/components/authorInfo.vue';
import VideoAction from '@/components/DetailSideInfoComponents/components/videoAction/index.vue';
import Comment from '@/components/DetailSideInfoComponents/components/comment/index.vue';
import TopBar from '@/components/TopBar/VideoDetailBar.vue';
import 'swiper/css/swiper.css';
import { immediateSendLog, sendLog } from '@/common/radar';
import { repalacePath, openNewWindow } from '@/common/utils';

export default defineComponent({
    components: {
        VideoAction,
        Swiper,
        SwiperSlide,
        Comment,
        AuthorInfo,
        TopBar,
    },
    props: {
        videoList: {
            type: Array as PropType<Array<Record<string, any> | undefined>>,
            default: () => [],
        },
        slideIndex: {
            type: Number,
            default: 0,
        },
        bizData: {
            type: Object,
            default: () => ({ pageSource: 'pc-video-detail' }),
        },
    },
    setup(props, ctx) {
        const { proxy } = getCurrentInstance() as any;

        const embedModalVisible = ref(false);

        const cursorIndex = ref(0);
        const slideIndex = ref(props.slideIndex);
        const prevSlideIndex = ref(props.slideIndex - 1);
        const swiperRef = ref();
        const videoRefs = ref([]);

        const muted = ref(false);

        const isError = ref(false);

        const isMouseWheel = ref<any>(null);

        const swiperOption = {
            direction: 'vertical',
            keyboard: true,
            noSwiping: true,
            noSwipingClass: 'controls',
            initialSlide: props.slideIndex,
            // observer: true,
            // observeSlideChildren: true,
            // mousewheel: {
            //     // thresholdDelta: 110,
            //     eventsTarged: '.video-content',
            //     releaseOnEdges: true,
            //     // thresholdTime: 1000
            //     // sensitivity: 0.5
            // },
            threshold: 30,
            on: {
                keyPress: (event: any) => {
                    if (event === 32) {
                        if (!(videoRefs.value[slideIndex.value] as any)?.getPaused()) (videoRefs.value[slideIndex.value] as any)?.pause();
                        else (videoRefs.value[slideIndex.value] as any)?.play();
                    }
                },
                slideChangeTransitionStart: (swiper: any) => {
                    prevSlideIndex.value = slideIndex.value;
                },
                slideChangeTransitionEnd: (swiper: any) => {
                    slideIndex.value = swiperRef.value.$swiper?.realIndex || props.slideIndex;

                    nextTick(() => {
                        (videoRefs.value[prevSlideIndex.value] as any)?.pause();
                        (videoRefs.value[prevSlideIndex.value] as any)?.onLeave();
                        if ((videoRefs.value[slideIndex.value] as any)?.status?.canPlay) {
                            (videoRefs.value[slideIndex.value] as any)?.onEnter();
                            (videoRefs.value[slideIndex.value] as any)?.play();
                        }
                    });

                    const path = `/@${(props.videoList as any)?.[slideIndex.value]?.kwai_id}/video/${
                        (props.videoList as any)?.[slideIndex.value]?.photo_id_str
                    }`;
                    repalacePath(path);

                    ctx.emit('slideChange', path, slideIndex.value);

                    if (props.videoList.length - slideIndex.value <= 3) ctx.emit('refresh');
                },
            },
        };

        const handleSlide = (direction: string) => {
            if (direction === 'prev') {
                swiperRef.value.$swiper.slidePrev();
            } else {
                swiperRef.value.$swiper.slideNext();
            }
        };

        const handleMousewheel = (e: any) => {
            if (isMouseWheel.value || Math.abs(e.wheelDeltaY) < 240) return;
            isMouseWheel.value = setTimeout(() => {
                if (e.wheelDeltaY < 0) {
                    swiperRef.value.$swiper.slideNext();
                } else {
                    swiperRef.value.$swiper.slidePrev();
                }
                clearTimeout(isMouseWheel.value);
                setTimeout(() => {
                    isMouseWheel.value = 0;
                }, 500);
            }, 200);
        };

        const handleShowModal = () => {
            proxy.$bus.emit('show-model', true);
            sendLog({
                type: 'show',
                name: 'DOWNLOAD_POP',
                value: {},
            });
        };

        const handleReturn = () => {
            // window.history.back();
            ctx.emit('return', slideIndex.value);
            sendLog({
                type: 'click',
                name: 'TOP_AREA',
                value: {
                    button_type: 'return',
                },
            });
        };

        const handleComment = () => {
            isShowComments.value = !isShowComments.value;
        };

        const handleProfile = () => {
            openNewWindow(`/@${(props.videoList as any)?.[slideIndex.value]?.kwai_id}?page_source=detail`);
        };

        const onloadeddata = (index: number) => {
            if (index === slideIndex.value)
                nextTick(() => {
                    (videoRefs.value[slideIndex.value] as any)?.onEnter();
                    (videoRefs.value[slideIndex.value] as any)?.play();
                });
        };

        const isShowComments = ref<boolean>(true);

        const videoPlayStatus = ref(false);

        onActivated(() => {
            if (videoPlayStatus.value) (videoRefs.value[slideIndex.value] as any)?.pause();
            else (videoRefs.value[slideIndex.value] as any)?.play();
        });

        onDeactivated(() => {
            videoPlayStatus.value = (videoRefs.value[slideIndex.value] as any)?.getPaused();
        });

        onMounted(() => {
            proxy.$bus.on('video-muted', (val: boolean) => {
                muted.value = val;
            });
        });

        return {
            handleShowModal,
            handleReturn,
            isShowComments,
            swiperOption,
            slideIndex,
            swiperRef,
            handleSlide,
            videoRefs,
            handleComment,
            embedModalVisible,
            handleProfile,
            onloadeddata,
            muted,
            isError,
            handleMousewheel,
        };
    },
});
