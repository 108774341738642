
import cookies from 'js-cookie'
import { defineComponent, ref, Ref, computed, SetupContext, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import List from './components/list/index.vue'
import { useList } from './hooks/useList'
import newEmpty from '@/components/new-empty/index.vue'
import { commonPvParams, formateNum, checkSeoReferrer, getQuery } from '@/common/utils'
import { setCommonParamsCookiesInServer } from '@/common/common-params'
import { immediateSendLog, getKwaiLog } from '@/common/radar';
import { getMetaInfo, header, extractTitleFromMetaInfo, getDefaultTitle, getLdJson } from '@/common/seo'

interface AlbumInfo {
  albumId: number,
  albumIdStr: string,
  authorId: number,
  authorName: string,
  count: number,
  viewCount: number,
  firstPhotoCaption: string,
  firstPhotoCover: Array<Record<string, string>>
}
export default defineComponent({
  components: {
    List,
    newEmpty
  },
  // layout: 'noFooterLayout',
  setup(props, ctx: SetupContext) {
    const fmp_time = Date.now();
    const beginTime = ref(0)
    const albumList = ref([])
    // const fetchDone = ref(false)
    const albumInfo: Ref<Record<string, any>> = ref({})
    // const cursor = ref('')
    const titleContente = computed(() => {
      return albumInfo.value.name || `${albumInfo.value.authorName} ${proxy.$root.$t('k_407163')}`
    })

    const subTitleContent = computed(() => {
      return proxy.$root.$t('k_407164').replace('${0}', albumInfo.value.count)
    })

    const imgUrl = computed(() => {
      return albumInfo.value.firstPhotoCover && albumInfo.value.firstPhotoCover[0]?.url || ''
    })

    const viewCount = computed(() => {
      return formateNum(albumInfo.value.viewCount) || 0
    })

    const {
      cursor,
      finished,
      loading,
      loadMorePost,
      onLoad,
      getAlbumFeed,
    } = useList({ feeds: albumList, albumInfo })

    const { proxy }: any = getCurrentInstance()

    const leftIconClick = () => {
      // if (checkSeoReferrer()) {
      //   const query = getQuery()
      //   proxy.$router.push({ path: '/foryou', query: { ...query, page_source: 'album' } });
      // } else {
      window.history.back()
      // }
    }

    const sendPv = () => {
      const params = commonPvParams({ defaultPageSource: '' })
      // 获取启动config
      const country = proxy.$store.state.startup.countryInfo
      const user_id = cookies.get('user_id')

      immediateSendLog({
        type: 'pv',
        name: 'PWA_COLLECTION_PHOTO_PAGE',
        value: {
          country,
          user_id,
          col_id: proxy.$route?.params?.albumId,
          ...params
        }
      })
    }

    const initData = async () => {
      loadMorePost()
    }

    onMounted(async () => {
      // 记录页面打开时间
      beginTime.value = new Date().getTime();
      const webLogger = getKwaiLog();


      try {
        webLogger?.plugins?.radar?.fmp(fmp_time);
      } catch (error) {
        console.error(error);
      }
      proxy.$bus.on('sendPv', sendPv);
      // 初始化发送pv埋点，登录成功后获取到user_id 继续上报一次
      sendPv()

      if (!proxy.albumList.length) {
        initData()
      } else {
        cursor.value = proxy.cursor
        albumList.value = proxy.feeds
      }

    })

    onUnmounted(() => {

    })

    return {
      albumList,
      imgUrl,
      finished,
      loading,
      cursor,
      albumInfo,
      titleContente,
      subTitleContent,
      formateNum,
      viewCount,
      loadMorePost,
      onLoad,
      getAlbumFeed,
      leftIconClick,
    }
  },
  async asyncData(ctx:any) {
    let feeds = []
    let seoData = []
    let metaInfoData = []
    let cursor = ''
    let title = getDefaultTitle(ctx)
    let albumInfo = {}
    // 60332411774305107
    if ((process as any).server) {
      const albumId = ctx?.route?.params?.albumId
      // const { photoId, share_device_id, shareBucket } = ctx.route.query
      setCommonParamsCookiesInServer({ bucket: ctx.store.state.startup.bucket, host: ctx.req.headers.host, ctx, countryInfo: ctx.store.state.startup.countryInfo })
      
      const url = `https://${ctx?.req?.headers?.host}${ctx?.route?.path}` || ''

      // 获取google ldjson 标签
      let seoPromise = getLdJson({ ctx, params: { url } })

      // 获取meta info
      let metaInfoPromise = getMetaInfo({ ctx, params: { url, locale: ctx.req.headers['accept-language'] }})
      let albumPromise = ctx.$axios.$$post('overseaAPI::/rest/o/w/pwa/feed/album', {
        albumId,
        count: 20,
      })
      let [albumIdRes, seoRes, metaInfoRes] = await Promise.all([albumPromise, seoPromise, metaInfoPromise])

      if (albumIdRes.result === 1) {
        albumInfo = albumIdRes.album
        feeds = albumIdRes.feeds
        cursor = albumIdRes.cursor
      }

      if (seoRes.status === 200) {
        seoData = seoRes.data
      }

      if (metaInfoRes.status === 200) {
        metaInfoData = metaInfoRes.data
        title = extractTitleFromMetaInfo({ ctx, metaInfoData})
      }
    }
    return {
      feeds,
      seoData,
      metaInfoData,
      title,
      cursor,
      albumInfo,
    }
  },
  head() {
    const head = header(this)
    return {
      ...head
    }
  },
})

